import {send, upload} from "../index";

// 导入用户线上会员卡
export function importUserRechargeCard (data, file) {
	return upload({
	  url: "/admin/userRechargeCard/import.do",
	  method: "POST",
	  data,
		file,
	});
}

// 导入兑换券列表
export function importCoupon (data, file) {
	return upload({
	  url: "/admin/systemCoupon/import.do",
	  method: "POST",
	  data,
		file,
	});
}

// 导入用户券
export function importUserCoupon (data, file) {
	return upload({
	  url: "/admin/userCoupon/import.do",
	  method: "POST",
	  data,
		file,
	});
}
